import { useEffect, useState } from 'react'
import type {
  PlacementResponse,
  TopItem,
} from 'src/utils/placements/fetchPlacementData'
import { getCookie, setCookie } from 'src/utils/getCookie'
import { getOrderForm } from 'src/sdk/checkout/data/checkout'

import Slider from '../ui/Slider/Slider'
import PlacementCard from './PlacementCard/PlacementCard'
import './placementsComponent.scss'
import { useAccountContext } from '../account/context'
import type { UserDecathlon } from '../account/types'

export type PlacementsComponentProps = {
  placementData: PlacementResponse & {
    categoryName?: string
    searchTerm?: string
  }
}

function setPlacementCookieIfNotExists(serverSession: string): string {
  const cookieName = 'newtail_search_session'
  const expirationTimeInDays = 1
  let sessionId = getCookie(cookieName)

  if (!sessionId) {
    sessionId = serverSession
    setCookie(cookieName, sessionId, expirationTimeInDays)
  }

  return sessionId
}

const updateOrderformUserinfo = async (
  sessionCookie: string,
  userDecathlon: UserDecathlon | null
) => {
  const { orderFormId } = await getOrderForm()

  return fetch('/api/changeMultipleCustomDataFields', {
    method: 'POST',
    body: JSON.stringify({
      orderFormId,
      appId: 'userinfo',
      session_id: sessionCookie,
      user_id: userDecathlon?.getUserProfile?.sub ?? 'n/a',
    }),
    headers: {
      Accept: 'application/vnd.vtex.ds.v10+json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  })
}

export const PlacementsComponent = ({
  placementData,
}: PlacementsComponentProps) => {
  const { userDecathlon } = useAccountContext()
  const [placementItems, setPlacementItems] = useState<TopItem[]>(
    placementData?.plp_top ?? placementData?.search_top ?? []
  )

  const sessionCookie =
    getCookie('newtail_search_session') || placementData?.sessionId

  useEffect(() => {
    setPlacementItems(placementData?.plp_top ?? placementData?.search_top ?? [])
  }, [placementData])

  useEffect(() => {
    if (!placementData?.sessionId) {
      return
    }

    setPlacementCookieIfNotExists(placementData?.sessionId)
  }, [placementData])

  useEffect(() => {
    updateOrderformUserinfo(sessionCookie, userDecathlon)
  }, [userDecathlon, sessionCookie])

  if (!placementItems.length) {
    return null
  }

  return (
    <section className="placements-component mb-10 sm:mb-14 mt-4 sm:mt-9">
      <Slider
        itemQuantity={placementItems.length}
        hasScrollbar={false}
        controlsStyle="middle"
      >
        {placementItems?.map((item) => {
          return (
            <li key={item.product_metadata.productId}>
              <PlacementCard
                productData={item}
                userDecathlon={userDecathlon}
                sessionCookie={sessionCookie}
              />
            </li>
          )
        })}
      </Slider>
    </section>
  )
}
