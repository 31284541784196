export function IconFilterMobile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 4C10.75 4.41421 10.4142 4.75 10 4.75L3 4.75C2.58579 4.75 2.25 4.41421 2.25 4C2.25 3.58579 2.58579 3.25 3 3.25L10 3.25C10.4142 3.25 10.75 3.58579 10.75 4Z"
        fill="#15181B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.75 4C21.75 4.41421 21.4142 4.75 21 4.75L14 4.75C13.5858 4.75 13.25 4.41421 13.25 4C13.25 3.58579 13.5858 3.25 14 3.25L21 3.25C21.4142 3.25 21.75 3.58579 21.75 4Z"
        fill="#15181B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 12C12.75 12.4142 12.4142 12.75 12 12.75L3 12.75C2.58579 12.75 2.25 12.4142 2.25 12C2.25 11.5858 2.58579 11.25 3 11.25L12 11.25C12.4142 11.25 12.75 11.5858 12.75 12Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.75 12C21.75 12.4142 21.4142 12.75 21 12.75L16 12.75C15.5858 12.75 15.25 12.4142 15.25 12C15.25 11.5858 15.5858 11.25 16 11.25L21 11.25C21.4142 11.25 21.75 11.5858 21.75 12Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 20C8.75 20.4142 8.41421 20.75 8 20.75L3 20.75C2.58579 20.75 2.25 20.4142 2.25 20C2.25 19.5858 2.58579 19.25 3 19.25L8 19.25C8.41421 19.25 8.75 19.5858 8.75 20Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.75 20C21.75 20.4142 21.4142 20.75 21 20.75L12 20.75C11.5858 20.75 11.25 20.4142 11.25 20C11.25 19.5858 11.5858 19.25 12 19.25L21 19.25C21.4142 19.25 21.75 19.5858 21.75 20Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0.25C10.4142 0.25 10.75 0.585786 10.75 1L10.75 7C10.75 7.41421 10.4142 7.75 10 7.75C9.58579 7.75 9.25 7.41421 9.25 7L9.25 1C9.25 0.585786 9.58579 0.25 10 0.25Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8.25C16.4142 8.25 16.75 8.58579 16.75 9L16.75 15C16.75 15.4142 16.4142 15.75 16 15.75C15.5858 15.75 15.25 15.4142 15.25 15L15.25 9C15.25 8.58579 15.5858 8.25 16 8.25Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16.25C8.41421 16.25 8.75 16.5858 8.75 17L8.75 23C8.75 23.4142 8.41421 23.75 8 23.75C7.58579 23.75 7.25 23.4142 7.25 23L7.25 17C7.25 16.5858 7.58579 16.25 8 16.25Z"
        fill="black"
      />
    </svg>
  )
}
