import type { FacetValue } from '../types'

type Props = {
  facetValue: FacetValue
  onFacetChange(value: { key: string; value: string }): void
  showQuantity?: boolean
}

export function Box({ facetValue, onFacetChange, showQuantity }: Props) {
  return (
    <label
      htmlFor={facetValue.value}
      className={`relative border px-4 py-1 cursor-pointer ${
        facetValue.selected
          ? 'border-restructure-border-information'
          : 'border-restructure-border-primary'
      } sm:hover:border-restructure-border-information font-roboto text-sm`}
      data-store-label
      data-testid="store-label"
    >
      <input
        type="checkbox"
        name={facetValue.key}
        id={facetValue.value}
        className="h-0 w-0 absolute top-0 left-0 invisible"
        checked={facetValue.selected}
        onChange={() => {
          onFacetChange({ key: facetValue?.key, value: facetValue?.value })
        }}
        data-value={facetValue.value}
        data-quantity={facetValue.quantity}
        data-store-checkbox
      />
      <span
        className={`mobile-body-regular-text3 tablet-body-regular-text3 desktop-body-regular-text3
          ${
            facetValue.selected
              ? 'text-restructure-action'
              : 'text-restructure-primary'
          }
        `}
      >
        {facetValue.label}{' '}
        {showQuantity && (
          <span
            className={`mobile-body-regular-text3 tablet-body-regular-text3 desktop-body-regular-text3 ${
              facetValue.selected
                ? 'text-restructure-action'
                : 'text-restructure-tertiary'
            }`}
          >
            ({facetValue.quantity})
          </span>
        )}
      </span>
    </label>
  )
}
