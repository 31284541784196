import { Link } from 'gatsby'
import { useCallback } from 'react'
import { InView } from 'react-intersection-observer'
import type { UserDecathlon } from 'src/components/account/types'
import { formatSrc } from 'src/components/restructure/utils/formatSrc'
import { Picture } from 'src/components/ui/Image'
import { formatterPrice } from 'src/sdk/checkout/data/utils/formatterPrice'
import type { TopItem } from 'src/utils/placements/fetchPlacementData'
import { sendPlacementEvent } from 'src/utils/placements/sendPlacementEvent'

type PlacementCardProps = {
  productData: TopItem
  userDecathlon?: UserDecathlon | null
  sessionCookie?: string
}

const PlacementCard = ({
  productData,
  userDecathlon,
  sessionCookie,
}: PlacementCardProps) => {
  const {
    product_metadata: {
      images,
      sellerOffer: { price, listPrice },
      link,
    },
    product_name: productName,
    click_url: clickUrl,
    impression_url: impressionUrl,
    view_url: viewUrl,
    ad_id: adId,
  } = productData

  const handleOnViewEvents = useCallback(() => {
    sendPlacementEvent({
      requestUrl: impressionUrl,
      sessionId: sessionCookie,
      userId: userDecathlon?.getUserProfile.sub ?? '',
    })
    sendPlacementEvent({
      requestUrl: viewUrl,
      sessionId: sessionCookie,
      userId: userDecathlon?.getUserProfile.sub ?? '',
    })
  }, [impressionUrl, sessionCookie, userDecathlon?.getUserProfile.sub, viewUrl])

  const handleClick = () => {
    if (!clickUrl || !sessionCookie) {
      return
    }

    sendPlacementEvent({
      requestUrl: clickUrl,
      sessionId: sessionCookie,
      userId: userDecathlon?.getUserProfile.sub ?? '',
    })
  }

  return (
    <InView
      key={adId}
      as="div"
      threshold={0.7}
      onChange={(inView) => {
        if (inView) {
          handleOnViewEvents()
        }
      }}
      triggerOnce
    >
      <Link onClick={handleClick} to={link} className="placement-card flex">
        <div className="w-[80px] min-w-[80px] sm:min-w-[140px] h-fit sm:w-[140px] bg-[#eeeff0]">
          <Picture
            sources={[
              {
                src: formatSrc(images[0].imageUrl, 80, 80),
                width: 80,
                height: 80,
                media: '(max-width: 480px)',
              },
              {
                src: images[0].imageUrl,
                width: 140,
                height: 140,
                media: '(max-width: 1024px)',
              },
            ]}
            img={{
              src: formatSrc(images[0].imageUrl, 140, 140),
              width: 140,
              height: 140,
              alt: productName,
              loading: 'eager',
              className: 'mix-blend-multiply',
            }}
          />
        </div>

        <div className="placement-product-info flex flex-col ml-2 sm:ml-3 gap-2 sm:gap-4 font-inter">
          <h3 className="text-xs sm:text-base text-black font-normal line-clamp-2  w-[158px] sm:w-[256px]">
            {productName}
          </h3>
          <div className="flex gap-2 items-center">
            <p className="text-black text-xs sm:text-xl font-medium">
              {formatterPrice(price)}
            </p>
            {price < listPrice && (
              <p className="text-[0.625rem] sm:text-sm text-darkGray font-normal line-through">
                {formatterPrice(listPrice)}
              </p>
            )}
          </div>
          <p className="text-[0.625rem] sm:text-sm text-tertiary font-normal">
            Patrocinado
          </p>
        </div>
      </Link>
    </InView>
  )
}

export default PlacementCard
