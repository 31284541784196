import { useCallback } from 'react'
import type { CurrencyCode, ViewItemListEvent } from '@faststore/sdk'
import { sendAnalyticsEvent, useSession } from '@faststore/sdk'
import type { Product } from 'src/components/restructure/product/types/product'

import type { AnalyticsItem } from '../types'

type Props = {
  products: Product[]
  title: string
  page: number
  pageSize: number
}

export const useViewItemListEvent = ({
  products,
  title,
  page,
  pageSize,
}: Props) => {
  const {
    currency: { code },
  } = useSession()

  const sendViewItemListEvent = useCallback(() => {
    sendAnalyticsEvent<ViewItemListEvent<AnalyticsItem>>({
      name: 'view_item_list',
      params: {
        item_list_name: title,
        item_list_id: title,
        items: products.map((product, index) => ({
          item_id: product.productId,
          item_name: product.productName,
          item_brand: product.brand,
          item_variant: product.items[0].itemId,
          price: product.items[0].offer.price,
          index: page * pageSize + index + 1,
          discount: product.items[0].offer.discount,
          currency: code as CurrencyCode,
          item_variant_name: product.items[0].name,
          product_reference_id: product.productId,
          arborescence: '',
        })),
      },
    })
  }, [code, page, pageSize, products, title])

  return { sendViewItemListEvent }
}
