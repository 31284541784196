import { useEffect, useMemo, useState } from 'react'
import { formatterPrice } from 'src/utils/formatterPrice'

import { Slider } from './Slider'
import { Accordion } from '../Accordion'

type Props = {
  max: number
  min: number
  onEnd: (value: number[]) => void
  defaultValue?: {
    to?: number
    from?: number
  }
}

export function RangePricing({ max, min, defaultValue, onEnd }: Props) {
  const [percentRange, setPercentRange] = useState([0, 100])

  function getValuePercent(value: number, percent: number) {
    return value * (percent / 100)
  }

  useEffect(() => {
    if (!defaultValue?.to || !defaultValue?.from) {
      return
    }

    const variantMax = (defaultValue.to / max) * 100
    const variantMin = (defaultValue.from / max) * 100

    setPercentRange([variantMin, variantMax])
  }, [defaultValue, min, max])

  const moneyValues = useMemo(() => {
    if (percentRange[0] === 0) {
      return [min, getValuePercent(max, percentRange[1])]
    }

    return [
      getValuePercent(max, percentRange[0]),
      getValuePercent(max, percentRange[1]),
    ]
  }, [max, min, percentRange])

  return (
    <Accordion
      title="Faixa de preço"
      index={2}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <g clipPath="url(#clip0_3937_229)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.24267 6.11869L5.3 5.1665L2 8.49984L5.3 11.8332L6.24267 10.881L4.552 9.17324H6H10.6667V9.17266L11.448 9.17256L9.75733 10.881L10.7 11.8332L14 8.49984L10.7 5.1665L9.75733 6.11869L11.448 7.82644L10.6667 7.82644L6 7.82644L4.552 7.82644L6.24267 6.11869Z"
              fill="#687787"
            />
          </g>
          <defs>
            <clipPath id="clip0_3937_229">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      }
    >
      <div className="mt-6 restructure-small-desktop:mt-0">
        <Slider
          value={percentRange}
          onAfterChange={(value) => {
            if (value[0] === 0) {
              return onEnd([min, getValuePercent(max, value[1])])
            }

            return onEnd([
              getValuePercent(max, value[0]),
              getValuePercent(max, value[1]),
            ])
          }}
          onChange={(value) => {
            setPercentRange(value)
          }}
          ariaLabel={[percentRange[0].toString(), percentRange[1].toString()]}
        />

        <div className="flex justify-between items-center pt-4">
          <p className="mobile-body-regular-text3 tablet-body-regular-text3 text-restructure-tertiary">
            {formatterPrice(moneyValues[0])}
          </p>
          <p className="mobile-body-regular-text3 tablet-body-regular-text3 text-restructure-tertiary">
            {formatterPrice(moneyValues[1])}
          </p>
        </div>
      </div>
    </Accordion>
  )
}
