import ProductGrid from 'src/components/product/ProductGridV2'
import Sentinel from 'src/sdk/search/SentinelV2'
import { ITEMS_PER_PAGE } from 'src/constants'
import type { Product } from 'src/components/restructure/product/types/product'

interface Props {
  page: number
  title: string
  products: Product[]
  totalCountOfRegisters: number
  type?: string
}

function GalleryPage({
  page,
  title,
  products,
  totalCountOfRegisters,
  type,
}: Props) {
  if (products == null) {
    return null
  }

  return (
    <>
      <Sentinel
        products={products}
        page={page}
        pageSize={ITEMS_PER_PAGE}
        title={title}
      />
      <ProductGrid
        totalCountOfRegisters={totalCountOfRegisters}
        products={products}
        page={page}
        pageSize={ITEMS_PER_PAGE}
        type={type}
        title={title}
      />
    </>
  )
}

export default GalleryPage
