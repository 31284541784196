import { useSearch } from '@faststore/sdk'
import type { MouseEvent } from 'react'
import { useEffect, useState } from 'react'
import type { SearchSort } from '@faststore/sdk/dist/search/useSearchState'

export const OptionsMap = {
  price_desc: 'Maior Preço',
  price_asc: 'Menor Preço',
  orders_desc: 'Top sales',
  // name_asc: 'A-Z',
  // name_desc: 'Z-A',
  release_desc: 'Data de lançamento',
  discount_desc: 'Melhor Desconto',
  score_desc: 'Relevância',
}

const keys = Object.keys(OptionsMap) as Array<keyof typeof OptionsMap>

type SortProp = {
  id?: string
}

function Sort({ id }: SortProp) {
  const {
    setSort,
    state: { sort },
  } = useSearch()

  const [optSelect, setOptSelect] = useState<SearchSort>(sort)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handler = () => setIsOpen(false)

    window.addEventListener('click', handler)

    return () => {
      window.removeEventListener('click', handler)
    }
  }, [])

  const handleOpenOptions = (e: MouseEvent) => {
    e.stopPropagation()
    setIsOpen((current) => !current)
  }

  return (
    <div className=" relative">
      <button
        className="flex items-center desktop-body-semibold-text3 text-restructure-secondary gap-1"
        onClick={handleOpenOptions}
      >
        Ordernar por
        {id === 'desktop' && (
          <span className="desktop-body-semibold-text3 text-restructure-action">
            {OptionsMap[optSelect as keyof typeof OptionsMap]}
          </span>
        )}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.46967 8.46967C5.76256 8.17678 6.23744 8.17678 6.53033 8.46967L12 13.9393L17.4697 8.46967C17.7626 8.17678 18.2374 8.17678 18.5303 8.46967C18.8232 8.76256 18.8232 9.23744 18.5303 9.53033L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L5.46967 9.53033C5.17678 9.23744 5.17678 8.76256 5.46967 8.46967Z"
            fill="#3E4751"
          />
        </svg>
      </button>
      {isOpen && (
        <ul className="absolute bg-white px-2 top-6 right-0 h-fit z-50 shadow-[0px 24px 24px rgba(0, 83, 125, 0.1)] border border-gray rounded min-w-[157px]">
          {keys.map((key, index) => (
            <li key={key}>
              <label
                onClick={() => {
                  setOptSelect(key)
                  setSort(key)
                }}
                aria-hidden="true"
                className={`relative flex items-center justify-between text-sm font-roboto py-[12px] px-2 ${
                  index > 0 ? 'border-t border-gray' : ''
                } ${
                  key === optSelect
                    ? 'text-restructure-action'
                    : 'text-neutral10'
                } `}
              >
                <input
                  type="radio"
                  name="sort"
                  value={key}
                  className="w-0 h-0 invisible absolute top-0 left-0"
                />
                <p>{OptionsMap[key]}</p>
                <span className="w-[20px]">
                  {key === optSelect && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="13"
                      viewBox="0 0 18 13"
                      fill="none"
                    >
                      <path
                        d="M6.99999 10.172L16.192 0.979004L17.607 2.393L6.99999 13L0.635986 6.636L2.04999 5.222L6.99999 10.172Z"
                        fill="#3643ba"
                      />
                    </svg>
                  )}
                </span>
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Sort
