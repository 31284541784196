import type { PropsWithChildren } from 'react'
import type { IStoreSelectedFacet } from '@generated/graphql'

import type { Facet, FacetValue } from '../types'

import './styles.scss'

type Props = {
  facet: Facet | undefined
  facetValue: FacetValue
  testId: string
  onFacetChange: (
    item: IStoreSelectedFacet,
    type?: 'RANGE' | 'BOOLEAN' | undefined
  ) => void
}

const Switch = ({
  children,
  facet,
  facetValue,
  onFacetChange,
}: PropsWithChildren<Props>) => {
  const classes = `switch-input ${facet?.name}-${facetValue?.label}`

  return (
    <div className="main-switch-component flex max-w-none justify-between items-center">
      <div className="main-switch-component__content">{children}</div>
      <span className="main-switch-component__button">
        <div
          className={`main-switch-component__button--slider ${
            facetValue?.selected ? 'checkedState' : ''
          }`}
        >
          <input
            placeholder="Marcas Decathlon - Vendidos e entregues por Decathlon."
            type="checkbox"
            checked={facetValue?.selected}
            onChange={() => {
              onFacetChange(
                { key: facetValue?.key, value: facetValue?.value },
                'BOOLEAN'
              )
            }}
            data-testid="filter-deca-checkbox"
            data-value={facetValue?.label.replaceAll(' ', '-').toLowerCase()}
            data-quantity={facetValue?.quantity}
            className={classes}
          />
          <div className="switch-thumb" />
        </div>
      </span>
    </div>
  )
}

export default Switch
