import { useState, useCallback, useEffect, useRef } from 'react'
import { useSearch } from '@faststore/sdk'
import SearchNotFound from 'src/components/common/SearchNotFound'
import { mark } from 'src/sdk/tests/mark'
import SortV2 from 'src/components/search/SortV2'
import type { ServerCollectionPageQueryQuery } from '@generated/graphql'
import { useLocation } from '@reach/router'
import { RelatedResearchBrand } from 'src/components/brands/RelatedResearch'
import { ContentSEOBrand } from 'src/components/brands/ContentSEO/'
import { RelatedResearch } from 'src/components/sports/RelatedResearch/RelatedResearch'
import { ContentSEO } from 'src/components/sports/ContentSEO'
import FilteredEmptyGallery from 'src/components/common/FilteredEmptyGallery'
import { OptionsMap } from 'src/components/search/SortV2/Sort'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { compareArrays } from 'src/utils/compareArrays'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import type { PlacementResponse } from 'src/utils/placements/fetchPlacementData'
import { PlacementsComponent } from 'src/components/SponsoredProducts/PlacementsComponent'

import Section from '../Section'
import ProductResultList from './ProductResultList'
import type { SearchResult } from './types'
import './product-gallery.scss'
import { useProductGalleryAnalytics } from './hooks/useGalleryEvents'
import GalleryBanner from './GalleryBanner'
import GalleryFilter from './filters/GalleryFilter'

export type SearchEngine = 'vtex' | 'linx'

export type GalleryType = 'search' | 'collection' | 'seller' | 'category'

interface CmsData {
  sections: Array<{
    data: {
      // eslint-disable-next-line
      items: any
    }
    id: string
    name: string
  }>
  status: string
}

interface Props {
  title: string
  categoryTree?: ServerCollectionPageQueryQuery['collection']
  dataSports?: CmsData | null
  type: GalleryType
  pageType?: string
  bannerMobile?: string
  bannerDesktop?: string
  dataBrand?: CmsData | null
  searchResult?: SearchResult
  placementData?: PlacementResponse & {
    categoryName?: string
    searchTerm?: string
  }
}

function ProductGallery({
  title,
  dataSports,
  type,
  bannerMobile,
  bannerDesktop,
  dataBrand,
  pageType,
  searchResult,
  placementData,
}: Readonly<Props>) {
  const {
    state: { term, sort, selectedFacets, page },
    itemsPerPage,
  } = useSearch()

  const [isFilterDesktopOpen] = useState<boolean>(true)

  const location = useLocation()
  const href = typeof window !== 'undefined' ? location.href : ''

  const isSearchPage = href?.includes('pesquisa')

  const totalCount = searchResult?.filteredRecords ?? 0

  const oldSort = useRef(sort)
  const oldFilters = useRef(selectedFacets)

  const displaySponsoredProducts = type === 'search' || type === 'category'

  useProductGalleryAnalytics({
    searchResult,
    term,
    type,
    title,
    totalCount,
    page,
    sort,
    selectedFacets,
    isSearchPage,
  })

  const getPageTitle = (str: string) => str?.replace(/-/g, ' ') ?? ''

  const sendSortOrderChangedEvent = useCallback(() => {
    dispatchAmplitudeEvent({
      eventName: 'Sort Order Changed',
      eventData: {
        ...getStandardObject(),
        'product list type': 'plp',
        'product list name': getPageTitle(title ?? term),
        'product list level': '1',
        'number filters applied': selectedFacets.length,
        'sort order': OptionsMap[sort as keyof typeof OptionsMap],
        'product list page number': page === 0 ? 1 : page,
        'number of products': searchResult?.filteredRecords ?? 0,
        'new sort order': OptionsMap[sort as keyof typeof OptionsMap],
        'old sort order':
          OptionsMap[oldSort.current as keyof typeof OptionsMap],
      },
    })

    oldSort.current = sort
  }, [
    page,
    searchResult?.filteredRecords,
    selectedFacets.length,
    sort,
    term,
    title,
  ])

  const sendFilterAppliedEvent = useCallback(() => {
    dispatchAmplitudeEvent({
      eventName: 'Filter Applied',
      eventData: {
        ...getStandardObject(),
        'product list type': 'plp',
        'product list name': getPageTitle(title ?? term),
        'product list level': '1',
        'filter details': selectedFacets.map((facet) => ({
          'filter type': facet.key,
          'filter value': facet.value,
        })),

        'number filters applied': selectedFacets.length,
        'sort order': OptionsMap[sort as keyof typeof OptionsMap],
        'product list page number': page === 0 ? 1 : page,
        'number of products': searchResult?.filteredRecords ?? 0,
      },
    })

    oldFilters.current = selectedFacets
  }, [title, term, selectedFacets, sort, page, searchResult?.filteredRecords])

  useEffect(() => {
    if (oldSort.current !== sort) {
      sendSortOrderChangedEvent()
    }

    if (!compareArrays(oldFilters.current, selectedFacets)) {
      sendFilterAppliedEvent()
    }
  }, [
    selectedFacets,
    sort,
    page,
    sendSortOrderChangedEvent,
    sendFilterAppliedEvent,
    searchResult,
  ])

  if (searchResult?.products.length === 0) {
    return (
      <Section className="w-full mx-auto">
        {selectedFacets.length > 0 ? (
          <FilteredEmptyGallery />
        ) : (
          <SearchNotFound term={title} />
        )}
      </Section>
    )
  }

  return (
    <Section
      data-testid="product-gallery"
      className={
        dataSports
          ? 'product-listing layout__content-full mt-14 md:mt-20'
          : 'product-listing layout__content-full'
      }
    >
      <GalleryBanner
        bannerMobile={bannerMobile}
        bannerDesktop={bannerDesktop}
      />

      <div className="gap-6 product-listing__content-grid layout__content">
        {isFilterDesktopOpen && (
          <div className="product-listing__filters-bar">
            <GalleryFilter
              searchResult={searchResult}
              title={getPageTitle(title ?? term)}
              totalCount={totalCount}
            />
          </div>
        )}

        <div
          className={`product-listing__results-wrapper ${
            !isFilterDesktopOpen ? 'full !bg-inherit !h-auto' : ''
          }`}
        >
          <div className="hidden-mobile">
            <div className="justify-end product-listing__results-wrapper-top">
              <div className="ml-auto mr-0 product-listing__sort">
                <SortV2 id="desktop" />
              </div>
            </div>
          </div>

          {displaySponsoredProducts && placementData && (
            <PlacementsComponent placementData={placementData} />
          )}

          <ProductResultList
            products={searchResult?.products}
            page={page}
            title={title ?? term}
            totalProducts={searchResult?.filteredRecords ?? 0}
            registerPerPage={itemsPerPage}
            type={pageType}
          />

          {dataSports && <RelatedResearch dataCMS={dataSports} />}
          {dataSports && <ContentSEO dataCMS={dataSports} />}
          {dataBrand && <RelatedResearchBrand dataCMS={dataBrand} />}
          {dataBrand && <ContentSEOBrand dataCMS={dataBrand} />}
        </div>
      </div>
    </Section>
  )
}

ProductGallery.displayName = 'ProductGallery'

export default mark(ProductGallery)
