import { useMemo } from 'react'
import { useSearch } from '@faststore/sdk'
import ProductGridSkeleton from 'src/components/skeletons/ProductGridSkeleton'
import ProductCard from 'src/components/restructure/product/ProductCard'
import type { Product } from 'src/components/restructure/product/types/product'
import './product-grid.scss'

interface Props {
  products: Product[]
  page: number
  pageSize: number
  totalCountOfRegisters: number
  type?: string
  title?: string
}

function ProductGrid({ products, totalCountOfRegisters, type, title }: Props) {
  const {
    state: { term, sort, page, selectedFacets },
    itemsPerPage,
  } = useSearch()

  const amplitudeData = useMemo(() => {
    return {
      term,
      sort,
      page: page === 0 ? 1 : page,
      selectedFacets,
      itemsPerPage,
      totalCountOfRegisters,
    }
  }, [page, selectedFacets, sort, term, itemsPerPage, totalCountOfRegisters])

  return (
    <ProductGridSkeleton loading={!products.length}>
      {products.map((product, idx: number) => (
        <li key={idx}>
          <ProductCard
            index={idx}
            key={`${idx}_${product.productId}`}
            product={product as unknown as Product}
            amplitudeData={amplitudeData}
            type={type}
            title={title}
            shelfRef={`vitrine - ${title?.replace(/-/g, ' ')}`}
          />
        </li>
      ))}
    </ProductGridSkeleton>
  )
}

export default ProductGrid
