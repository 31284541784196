import { useSearch } from '@faststore/sdk'
import SortV2 from 'src/components/search/SortV2'
import type { Facet, FacetValue } from 'src/components/search/FilterV2/types'
import { PlusIcon } from 'src/components/Icons/PlusIcon'
import { CloseButtonIcon } from 'src/components/Icons/CloseButtonIcon'

import { IconFilterMobile } from '../utils/icons'

type GalleryFilterMobileProps = {
  title: string
  totalCount: number
  facetsv2: Facet[] | undefined
  setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
  isFilterOpen: boolean
}

const GalleryFilterMobile = ({
  title,
  totalCount,
  facetsv2,
  isFilterOpen,
  setIsFilterOpen,
}: GalleryFilterMobileProps) => {
  const {
    state: { selectedFacets },

    setFacet,
    setFacets,
  } = useSearch()

  const facetBrandDecathlon = facetsv2?.find(
    (facet) => facet.key === '77560' || facet.key === 'marca-propria'
  )

  const facetBrandDecathlonValue = facetBrandDecathlon?.values.find(
    (value) => value.value === '77654' || value.value === 'marcas-decathlon'
  )

  const cashbackFacet = facetsv2?.find((facet) => facet.key === 'sellername')
  const cashbackFacetValue = cashbackFacet?.values?.find(
    (values) => values.value === 'iguasport-ltda'
  )

  const handleFilterClick = (facetValue: FacetValue) => {
    if (facetValue.selected) {
      const newFacet = selectedFacets.filter(
        (obj) => obj.key !== facetValue.key
      )

      setFacets(newFacet)
    } else {
      setFacet({
        key: facetValue.key,
        value: facetValue.value,
      })
    }
  }

  return (
    <div className="w-full">
      <div className="w-full flex items-center justify-center mt-4 mb-6 display-mobile">
        <button
          data-testid="open-filter-button"
          aria-label="Open Filters"
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          className="w-[48px] h-[48px] flex items-center justify-center rounded-round border border-restructure-background-neutral-5 flex-[0_0_auto]"
        >
          <IconFilterMobile />
        </button>

        <div className="block h-[40px] w-[1px] mx-5 bg-restructure-background-neutral-5" />

        <div className="filter-mobile-scroll-container flex gap-2 flex-1 overflow-scroll">
          {cashbackFacet && cashbackFacetValue && (
            <button
              onClick={() => handleFilterClick(cashbackFacetValue)}
              data-testId={`desktop-${cashbackFacet.key}`}
              className={`flex gap-2 h-[48px] px-4 items-center justify-center border rounded-round min-w-fit ${
                cashbackFacetValue.selected
                  ? 'border-black'
                  : 'border-restructure-background-neutral-5 '
              }`}
            >
              <p className="font-normal text-sm font-inter text-black">
                Cashback disponível
              </p>
              {cashbackFacetValue.selected ? (
                <CloseButtonIcon size={20} />
              ) : (
                <PlusIcon width={20} height={20} color="black" />
              )}
            </button>
          )}

          {facetBrandDecathlon && facetBrandDecathlonValue && (
            <button
              onClick={() => handleFilterClick(facetBrandDecathlonValue)}
              data-testId={`desktop-${facetBrandDecathlon.key}`}
              className={`flex gap-2 h-[48px] px-4 items-center justify-center border rounded-round min-w-fit ${
                facetBrandDecathlonValue.selected
                  ? 'border-black'
                  : 'border-restructure-background-neutral-5 '
              }`}
            >
              <p className="font-normal text-sm font-inter text-black">
                Marcas Decathlon
              </p>
              {facetBrandDecathlonValue.selected ? (
                <CloseButtonIcon size={20} />
              ) : (
                <PlusIcon width={20} height={20} color="black" />
              )}
            </button>
          )}
        </div>
      </div>

      <div className="flex items-center justify-between mb-2 ">
        <h2 className="text-base md:text-xl max-w-[220px] md:max-w-none flex flex-col gap-1 md:gap-2 capitalize text-[#001018] font-semibold md:font-medium">
          {title}
          <span
            data-testid="total-product-count"
            data-count={totalCount}
            className="text-xs md:text-sm text-restructure-tertiary"
          >
            ({`${totalCount.toLocaleString()} resultados`})
          </span>
        </h2>
        <div className="display-mobile">
          <SortV2 id="mobile" />
        </div>
      </div>
    </div>
  )
}

export default GalleryFilterMobile
