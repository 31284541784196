import { useMemo, useState } from 'react'
import FilterV2 from 'src/components/search/FilterV2'

import type { SearchResult } from '../types'
import GalleryFilterMobile from './GalleryFilterMobile'

type GalleryFilterProps = {
  searchResult: SearchResult | undefined
  title?: string
  totalCount: number
}

const GalleryFilter = ({
  searchResult,
  title,
  totalCount,
}: GalleryFilterProps) => {
  const facetsv2 = useMemo(() => {
    return searchResult?.facets
  }, [searchResult])

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)

  return (
    <>
      <GalleryFilterMobile
        facetsv2={facetsv2}
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        title={title ?? ''}
        totalCount={totalCount}
      />
      <FilterV2
        isOpen={isFilterOpen}
        facetsv2={facetsv2}
        onDismiss={() => setIsFilterOpen(false)}
      />
    </>
  )
}

export default GalleryFilter
