import { Modal } from 'src/components/common/Modal'
import { useMemo, useState } from 'react'
import type { IStoreSelectedFacet } from '@faststore/api'

import type { Facet } from '../types'
import { AllBrandsModal } from './AllBrandsModal'
import { Checkbox } from '../Checkbox'

import './brands.scss'

type Props = {
  facet: Facet
  onFacetChange: (value: IStoreSelectedFacet) => void
}

export function BrandFilter({ facet, onFacetChange }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [isCollapse, setIsCollapse] = useState(true)
  const [openTab, setOpenTab] = useState(true)

  function toggleTab() {
    if (openTab) {
      setOpenTab(false)
      setIsCollapse(true)
    } else {
      setOpenTab(true)
    }
  }

  const brands = useMemo(() => {
    if (!isCollapse) {
      return facet.values
    }

    return facet.values.slice(0, 6)
  }, [facet, isCollapse])

  return (
    <div className="border-b border-mediumGray py-6 restructure-small-desktop:pb-8">
      <div
        className="flex justify-between items-center w-full header-tab-brands-filter sm:hidden"
        onClick={() => toggleTab()}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        aria-hidden={openTab}
      >
        <h3 className="flex gap-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.33333 2.5H2V7.83333H7.33333V2.5ZM7.33333 9.16667H2V14.5H7.33333V9.16667ZM8.66667 2.5H14V7.83333H8.66667V2.5ZM14 9.16667H8.66667V14.5H14V9.16667Z"
              fill="#687787"
            />
          </svg>
          <span className="mobile-body-semibold-text3 tablet-body-semibold-text3 text-restructure-secondary">
            {' '}
            Marcas
          </span>
        </h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          className="arrow-tab"
        >
          <path
            d="M4.99999 3.78138L8.29999 0.481384L9.24266 1.42405L4.99999 5.66672L0.757324 1.42405L1.69999 0.481384L4.99999 3.78138Z"
            fill="#4E5D6B"
          />
        </svg>
      </div>
      <h3 className="flex gap-2 items-center sm:flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.33333 2.5H2V7.83333H7.33333V2.5ZM7.33333 9.16667H2V14.5H7.33333V9.16667ZM8.66667 2.5H14V7.83333H8.66667V2.5ZM14 9.16667H8.66667V14.5H14V9.16667Z"
            fill="#687787"
          />
        </svg>
        <span className="desktop-body-semibold-text3 text-restructure-secondary">
          {' '}
          Marcas
        </span>
      </h3>
      {openTab && (
        <>
          <div className="mt-4">
            <ul className="flex flex-wrap gap-[19px] w-full">
              {brands.map((brand) => {
                return (
                  <Checkbox
                    key={brand.value}
                    type="default"
                    facet={brand}
                    onFacetChange={onFacetChange}
                    showQuantity={false}
                  />
                )
              })}
            </ul>
            <button
              className=" mt-7 hidden sm:block mobile-body-semibold-text3 tablet-body-semibold-text3 desktop-body-semibold-text3 text-restructure-action"
              onClick={() => setIsOpen(true)}
            >
              Ver todas
            </button>
            {isCollapse && (
              <button
                className=" mt-7 block sm:hidden mobile-body-semibold-text3 tablet-body-semibold-text3 desktop-body-semibold-text3 text-restructure-action"
                onClick={() => setIsCollapse(false)}
              >
                Ver todas
              </button>
            )}
          </div>

          <Modal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            className="rounded max-w-[726px] w-full max-h-[484px] h-full overflow-hidden"
          >
            <AllBrandsModal
              brands={facet.values}
              onFacetChange={onFacetChange}
              onClose={() => setIsOpen(false)}
            />
          </Modal>
        </>
      )}
    </div>
  )
}
