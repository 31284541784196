export function compareArrays<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) {
    return false
  }

  function compareObjects(obj1: T, obj2: T): boolean {
    const keys1 = Object.keys(obj1 as unknown as keyof T)
    const keys2 = Object.keys(obj2 as unknown as keyof T)

    if (keys1.length !== keys2.length) {
      return false
    }

    for (const key of keys1) {
      if (obj1[key as unknown as keyof T] !== obj2[key as unknown as keyof T]) {
        return false
      }
    }

    return true
  }

  for (let i = 0; i < arr1.length; i++) {
    if (!compareObjects(arr1[i], arr2[i])) {
      return false
    }
  }

  return true
}
