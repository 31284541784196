import { useEffect, useMemo, useState } from 'react'
import type { IStoreSelectedFacet } from '@generated/graphql'
import { useLocation } from '@reach/router'
import Logo from 'src/components/ui/Logo'
import Cashback from 'src/images/icons/Cashback'

import './filter.scss'

import type { Facet, FacetValue } from './types'
import { colorConfig, variantsFilter } from './filtersConfig'
import { BrandFilter } from './BrandFilter'
import { RangePricing } from './PriceRange'
import { FacetSection } from './FacetSection'
import Switch from './Switch'

interface FacetsProps {
  testId: string
  facetsv2?: Facet[]
  indicesExpanded: Set<number>
  onFacetChange: (item: IStoreSelectedFacet, type?: 'RANGE' | 'BOOLEAN') => void
  onAccordionChange: (index: number) => void
}

type RateProps = {
  key: string
  quantity: number
  label: string
  selected: boolean
  value: string
}

type FacetType = {
  key: string
  quantity: number
  selected: boolean
  range: {
    from: number
    to: number
  }
  value: string
  label: string
}

const formatRange = (min: number, max: number) =>
  `${min.toFixed(2)}:${max.toFixed(2)}`

function mapOrderFacets(facets: Facet[], order: string[], key: 'key' | 'name') {
  facets.sort((a, b) => {
    const A = a[key]
    const B = b[key]

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1
    }

    return -1
  })

  return facets
}

function Facets({
  facetsv2,
  testId,
  onFacetChange,
  onAccordionChange,
}: FacetsProps) {
  const location = useLocation()

  const isPriceFilterApplied = location.search.includes('%3A')

  const priceFilterValues = location.search
    ?.split('&')
    ?.filter((e) => e.includes('%3A'))[0]
    ?.replace('?', '')
    ?.replace('2=', '')
    ?.split('%3A')

  const initialValueActive = isPriceFilterApplied
    ? {
        key: 'price',
        quantity: 1,
        selected: true,
        range: {
          from: Number(priceFilterValues[0]) ?? 0,
          to: Number(priceFilterValues[1]) ?? 0,
        },
        value: 'range',
        label: '',
      }
    : {
        key: 'price',
        quantity: 1,
        selected: true,
        range: {
          from: 0,
          to: 0,
        },
        value: 'range',
        label: '',
      }

  const [valueActive, setValueActive] = useState<FacetType>(initialValueActive)

  const items = useMemo(() => {
    const queryURL = location.search.replace('?', '')
    const urlParams = new URLSearchParams(queryURL)
    const rangeFilter = urlParams.get('2')?.split(':')

    const filterActives = facetsv2?.reduce((acc, item) => {
      const newItem = { ...item, values: item.values, showAll: false }

      if (item.name === 'Gênero') {
        return [newItem, ...acc]
      }

      if (item.name === 'Avaliações') {
        newItem.values.sort((rate1: RateProps, rate2: RateProps) => {
          return rate1.label < rate2.label ? 1 : -1
        })
      }

      if (item.name === 'Cor predominante') {
        const newFacetValues = newItem.values.filter((color) =>
          Boolean(colorConfig[color.label])
        )

        newItem.values = newFacetValues
      }

      acc.push(newItem)

      return acc
    }, [] as Facet[])

    const facetPriceFilter = filterActives?.find((facet) => facet.key === '2')

    if (facetPriceFilter) {
      facetPriceFilter.values = [
        facetPriceFilter?.values[0],
        {
          key: '2',
          value: '3',
          label: '',
          quantity: 1,
          selected: true,
          range: {
            from: Number(rangeFilter?.[0] ?? 0),
            to: Number(rangeFilter?.[1] ?? 0),
          },
        },
        facetPriceFilter.values.slice(-1)[0],
      ]
    }

    const orderListFacet = [
      'Marca Própria', // Marca Propria
      'Cor predominante', // Cor
      'Preço', // Preço
      'Gênero', // Genero
      'Idade', // Idade
      'Tamanho', // Tamanho
      'Categoria', // Categoria
      'Avaliações', // Avaliações
      'Marca', // Marcas
      'Esporte', // Esportes
      'Condições', // Condições
    ]

    return mapOrderFacets(filterActives ?? [], orderListFacet, 'name') ?? []
  }, [facetsv2, location.search])

  const getMinimumPrice = (facetValues: FacetValue[]) => {
    let minimumValue = Infinity

    facetValues.forEach((item) => {
      if (item.range && item.range.from < minimumValue) {
        minimumValue = item.range.from
      }
    })

    return minimumValue
  }

  const getMaxPrice = (facetValues: FacetValue[]) => {
    let maxValue = -Infinity

    facetValues.forEach((value) => {
      if (value.range && value.range.to > maxValue) {
        maxValue = value.range.to
      }
    })

    return maxValue
  }

  useEffect(() => {
    setValueActive({
      key: 'price',
      quantity: 1,
      selected: true,
      range: {
        from: 0,
        to: 0,
      },
      value: 'range',
      label: '',
    })
  }, [location.pathname])

  return (
    <div
      key={location.pathname}
      className="filter"
      data-store-filter
      data-testid={testId}
    >
      {items?.map((facet) => {
        if (facet.name.toLocaleLowerCase() === 'marca própria') {
          const facetValue = facet.values.find(
            (value) => value.label.toLocaleLowerCase() === 'marcas decathlon'
          ) as FacetValue

          return (
            <div className="p-4 border rounded-md main-filters border-gray hidden-mobile font-inter">
              <Switch
                testId={`desktop-${facet.key}`}
                facet={facet}
                facetValue={facetValue}
                onFacetChange={onFacetChange}
              >
                <h2 className="flex items-center gap-2 desktop-body-semibold-text2 text-secondary">
                  Marcas
                  <Logo width={108} height={16} bgFill="#3643BA" />
                </h2>
                <p className="desktop-caption-regular text-restructure-tertiary">
                  Produtos com tecnologia Decathlon
                </p>
              </Switch>
            </div>
          )
        }

        if (facet.name.toLocaleLowerCase() === 'sellername') {
          const cashbackFacetValue = facet.values.find(
            (value) => value.label.toLocaleLowerCase() === 'iguasport ltda'
          ) as FacetValue

          return cashbackFacetValue ? (
            <div className="p-4 my-3 border rounded-md main-filters border-gray hidden-mobile font-inter">
              <Switch
                testId={`desktop-${facet.key}`}
                facet={facet}
                facetValue={cashbackFacetValue}
                onFacetChange={onFacetChange}
              >
                <h2 className="flex items-center gap-2 desktop-body-semibold-text2 text-secondary">
                  Cashback disponível
                  <Cashback size={24} color="#3643BA" />
                </h2>
                <p className="desktop-caption-regular text-tertiary">
                  Produtos para usar seu cashback
                </p>
              </Switch>
            </div>
          ) : null
        }

        if (facet.name === 'Preço') {
          const maxPrice = getMaxPrice(facet.values)
          const minPrice = getMinimumPrice(facet.values)

          return (
            <RangePricing
              max={Number(maxPrice)}
              min={Number(minPrice)}
              defaultValue={valueActive?.range}
              onEnd={(value: number[]) => {
                setValueActive({
                  key: 'price',
                  quantity: 1,
                  selected: true,
                  range: {
                    from: value[0],
                    to: value[1],
                  },
                  value: 'range',
                  label: '',
                })

                onFacetChange(
                  { key: '2', value: formatRange(value[0], value[1]) },
                  'RANGE'
                )
              }}
            />
          )
        }

        if (!variantsFilter[facet.name]) {
          return facet.name
        }

        if (facet.name === 'Marca') {
          return <BrandFilter facet={facet} onFacetChange={onFacetChange} />
        }

        return (
          <FacetSection
            key={facet.key}
            facet={facet}
            checkboxType={variantsFilter[facet.name].type}
            onFacetChange={onFacetChange}
            icon={variantsFilter[facet.name].icon}
            onAccordionChange={onAccordionChange}
            defaultNumberOpts={variantsFilter[facet.name].defaultNumberOpts}
          />
        )
      })}
    </div>
  )
}

export default Facets
