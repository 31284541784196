/* eslint-disable @typescript-eslint/no-explicit-any */
import { navigate } from 'gatsby'
import type { Facet } from '@faststore/sdk/dist/search/useSearchState'
import type { IStoreSelectedFacet } from '@faststore/api'

interface SelectedFacetsProps {
  filteredFacets: IStoreSelectedFacet[]
  selectedFacets: IStoreSelectedFacet[]
  removeAllFacet: (item: any) => void
  removeFacet: (item: Facet) => void
}

interface RemoveFacetCustomProps {
  allFacets: IStoreSelectedFacet[]
  removalFunc: (item: any) => void
  typeOfRemoval: 'all' | 'single'
  singleFacet?: Facet
}

const navigateToSearch = (params: URLSearchParams) => {
  navigate(
    `/pesquisa?q=${params.get('q')}&sort=${params.get(
      'sort'
    )}&page=${params.get('page')}`
  )
}

const navigateToCategory = (allFacets: any[], categoryKey: string) => {
  const category = allFacets.find((item) => item.key === categoryKey)?.value

  if (category) {
    navigate(`/${category}`)
  }
}

const handleFirstFacetRemoval = (allFacets: any[], singleFacet: any) => {
  const newUrl = window.location.pathname
  const newSearchParams = new URLSearchParams(window.location.search)
  const isUnique = allFacets.length === 1

  if (!isUnique) {
    const currentSelectedFacets = newSearchParams.getAll(
      newSearchParams.get('facets')?.split(',')[0] ?? ''
    )

    const toNavigate = `${newUrl}?${newSearchParams}`
      .toString()
      .replace(`&${singleFacet?.key}=${currentSelectedFacets[0]}`, '')

    navigate(toNavigate)

    return
  }

  isUnique && singleFacet?.key && newSearchParams.delete(singleFacet.key)
  isUnique && newSearchParams.delete('facets')
  navigate(`${newUrl}?${newSearchParams}`)
}

const handleAllRemoval = (allFacets: any[], removalFunc: any) => {
  removalFunc({ type: 'selectFacets', payload: [] })

  if (allFacets.some((item) => item.key === 'category-1')) {
    navigateToCategory(allFacets, 'category-1')

    return
  }

  const params = new URLSearchParams(window.location.search)

  if (params.get('q') === null) {
    const categoryArray = window.location.pathname
      .split('/')
      .filter((e) => e !== '')

    if (categoryArray.length > 1) {
      navigate(
        `/pesquisa?q=${
          categoryArray[categoryArray.length - 2]
        }&sort=score_desc&page=0`
      )
    }

    return
  }

  navigateToSearch(params)
}

const facetNamesMap = {
  'Iguasport LTDA': 'Cashback disponível',
}

const SelectedFacets = ({
  filteredFacets,
  selectedFacets,
  removeAllFacet,
  removeFacet,
}: SelectedFacetsProps) => {
  const removeFacetCustom = ({
    allFacets,
    removalFunc,
    typeOfRemoval,
    singleFacet,
  }: RemoveFacetCustomProps): void => {
    if (typeOfRemoval === 'all') {
      handleAllRemoval(allFacets, removalFunc)

      return
    }

    if (typeOfRemoval === 'single') {
      if (singleFacet?.key === 'category-1' && allFacets.length === 2) {
        navigate(
          `/${allFacets.find((item) => item.key === 'category-1')?.value}`
        )

        return
      }

      if (singleFacet?.key === 'category-2') {
        const newUrl = window.location.href
          .replace(`${singleFacet.value}/`, '')
          .replace(`&category-2=${singleFacet.value}`, '')
          .replace('%2Ccategory-2', '')
          .split('/')
          .slice(3)
          .join('/')

        navigate(`/${newUrl}`)

        return
      }

      if (
        allFacets.findIndex((item) => item.value === singleFacet?.value) === 0
      ) {
        handleFirstFacetRemoval(allFacets, singleFacet)
      }
    }

    removalFunc(singleFacet)
  }

  return (
    <div
      className={`selected-filters mb-6 ${
        filteredFacets.length === 0 ? 'hidden' : ''
      }`}
    >
      <div className="flex gap-[12px] flex-wrap">
        {filteredFacets.length !== 0 &&
          filteredFacets.map((facet: any, idx) => {
            return (
              <div
                key={`${facet.value}-${idx}`}
                className="flex w-max gap-2 px-2 border border-restructure-border-information items-center"
              >
                <p
                  className="mobile-body-regular-text3 tablet-body-regular-text3 desktop-body-regular-text3 text-restructure-action"
                  data-id={facet.key}
                >
                  {facetNamesMap[facet.label as keyof typeof facetNamesMap] ??
                    facet.label.replaceAll('-', ' ')}
                </p>
                <button
                  aria-label="Remove Selected Filter"
                  onClick={() =>
                    removeFacetCustom({
                      allFacets: selectedFacets,
                      removalFunc: removeFacet,
                      typeOfRemoval: 'single',
                      singleFacet: facet,
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M5.99999 4.82166L10.125 0.696655L11.3033 1.87499L7.17832 5.99999L11.3033 10.125L10.125 11.3033L5.99999 7.17832L1.87499 11.3033L0.696655 10.125L4.82166 5.99999L0.696655 1.87499L1.87499 0.696655L5.99999 4.82166Z"
                      fill="#3643BA"
                    />
                  </svg>
                </button>
              </div>
            )
          })}
      </div>
      <button
        className="mobile-body-semibold-text3 tablet-body-semibold-text3 desktop-body-semibold-text3 text-restructure-action mt-4"
        onClick={() =>
          removeFacetCustom({
            allFacets: selectedFacets,
            removalFunc: removeAllFacet,
            typeOfRemoval: 'all',
          })
        }
      >
        Limpar filtros
      </button>
    </div>
  )
}

export default SelectedFacets
