type GalleryBannerProps = {
  bannerDesktop?: string
  bannerMobile?: string
}

const GalleryBanner = ({ bannerDesktop, bannerMobile }: GalleryBannerProps) => {
  return bannerDesktop && bannerMobile ? (
    <>
      <img
        src={bannerDesktop}
        alt="Copa do mundo | Decathlon"
        className="desk"
      />

      <img src={bannerMobile} alt="Copa do mundo | Decathlon" className="mob" />
    </>
  ) : null
}

export default GalleryBanner
