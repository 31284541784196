import { useSearch } from '@faststore/sdk'
import type { Product } from 'src/components/restructure/product/types/product'

import { Pagination } from './Pagination'
import GalleryPage from './ProductGalleryPageV2'

interface ProductResultListProps {
  title: string
  totalProducts: number
  products: Product[] | undefined
  registerPerPage: number
  type?: string
  page: number
}

function ProductResultList({
  title,
  products,
  totalProducts,
  registerPerPage,
  type,
  page,
}: ProductResultListProps) {
  const { setPage, state } = useSearch()

  if (!products) {
    return null
  }

  return (
    <div className="product-listing__results">
      <ul className="product-grid grid gap-x-2 gap-y-8 sm:gap-[10px] md:gap-4 md:gap-y-[56px] mobile:grid-cols-2 small:grid-cols-3">
        <GalleryPage
          key={`gallery-page-${page}`}
          products={products}
          page={page}
          title={title}
          totalCountOfRegisters={totalProducts}
          type={type}
        />
      </ul>

      <Pagination
        onPageChange={setPage}
        totalCountOfRegisters={totalProducts}
        currentPage={state.page === 0 ? 1 : state.page}
        registersPerPage={registerPerPage}
      />
    </div>
  )
}

export default ProductResultList
