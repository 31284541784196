import { useEffect, useState } from 'react'
import { sendSearchQueryEvent } from 'src/sdk/analytics/platform/intelligentSearch'
import { buildProductListObject } from 'src/utils/restructure/analytics/amplitude/buildProductListObject'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import { OptionsMap } from 'src/components/search/SortV2/Sort'
import type { Facet } from '@faststore/sdk/dist/search/useSearchState'

import type { SearchResult } from '../types'
import type { GalleryType } from '../ProductGallery'

type UseProductGalleryAnalyticsProps = {
  searchResult: SearchResult | undefined
  term: string | null
  type: GalleryType
  title: string
  totalCount: number | undefined
  page: number
  sort: string
  selectedFacets: Facet[]
  isSearchPage: boolean
}

export const useProductGalleryAnalytics = ({
  searchResult,
  term,
  type,
  title,
  totalCount,
  page,
  sort,
  selectedFacets,
  isSearchPage,
}: UseProductGalleryAnalyticsProps) => {
  const [lastTerm, setLastTerm] = useState<null | string>(null)

  const standardObj = getStandardObject()

  const getPageTitle = (str: string) => str?.replace(/-/g, ' ') ?? ''

  useEffect(() => {
    if (!searchResult || typeof window === 'undefined' || lastTerm === term) {
      return
    }

    if (isSearchPage) {
      sendSearchQueryEvent({
        name: 'intelligent_search_query',
        params: {
          url: window.location.href,
          results: searchResult.filteredRecords,
        },
      })
    }

    const productListObj = buildProductListObject({
      listType: 'plp',
      listName: getPageTitle(title ?? term),
      productsCount: searchResult?.filteredRecords ?? 0,
      sort: OptionsMap[sort as keyof typeof OptionsMap],
      selectedFacets,
      page,
    })

    dispatchAmplitudeEvent({
      eventName: 'Product List Viewed',
      eventData: {
        ...standardObj,
        ...productListObj,
      },
    })

    setLastTerm(term)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult, term])

  useEffect(() => {
    if (type !== 'search') {
      return
    }

    dispatchAmplitudeEvent({
      eventName: 'Search Results Viewed',
      eventData: {
        ...getStandardObject(),
        'search term': title,
        'number search results': totalCount,
      },
    })
  }, [title, totalCount, type])

  return {
    lastTerm,
  }
}
