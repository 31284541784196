import axios from 'axios'

type Props = {
  requestUrl?: string
  sessionId?: string
  userId?: string
}

export async function sendPlacementEvent({
  requestUrl,
  sessionId,
  userId,
}: Props) {
  if (!requestUrl) {
    return
  }

  try {
    await axios.post(
      requestUrl,
      {
        user_id: userId ?? null,
        session_id: sessionId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    )
  } catch {
    throw new Error('Erro ao enviar evento de placement')
  }
}
