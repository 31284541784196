import Skeleton from 'react-loading-skeleton'
import Section from 'src/components/sections/Section'
import type { PropsWithChildren } from 'react'
import { useMobile } from 'src/hooks/useMobile'

import 'react-loading-skeleton/dist/skeleton.css'

interface Props {
  loading?: boolean
}

function ProductGridSkeleton({
  children,
  loading = true,
}: PropsWithChildren<Props>) {
  const { isMobile } = useMobile()

  return loading ? (
    <Section>
      {isMobile ? (
        <>
          <ul className="flex flex-wrap m-0 gap-[24px] touch-pan-y">
            {Array.from({ length: 9 }, (__, idx) => (
              <li key={String(idx)} className="w-full flex justify-between">
                <Skeleton height={131} width={131} borderRadius={0} />
                <div className="w-full block ml-[12px]">
                  <Skeleton
                    height={21}
                    className="mt-[4px] w-full"
                    borderRadius={0}
                  />
                  <Skeleton
                    height={48}
                    className="mt-[4px] w-full"
                    borderRadius={0}
                  />
                  <Skeleton
                    height={20}
                    className="mt-[6px] w-full"
                    borderRadius={0}
                  />
                  <Skeleton
                    height={16}
                    className="mt-[8px] w-full"
                    borderRadius={0}
                  />
                </div>
              </li>
            ))}
          </ul>
          <div className="flex justify-center w-full mt-[24px] mb-[80px]">
            <div className="text-center">
              <Skeleton height={40} width={280} borderRadius={0} />
              <Skeleton height={21} className="mt-[16px]" borderRadius={0} />
            </div>
          </div>
        </>
      ) : (
        <>
          <ul className="flex flex-wrap m-0 gap-y-[56px] gap-x-[24px]">
            {Array.from({ length: 36 / 2 }, (__, idx) => (
              <li
                key={String(idx)}
                className="max-w-[302px] md:w-[calc(100%/3-20px)]"
              >
                <Skeleton height={302} borderRadius={0} />
                <Skeleton height={21} className="mt-[12px]" borderRadius={0} />
                <Skeleton height={48} className="mt-[8px]" borderRadius={0} />
                <Skeleton height={20} className="mt-[16px]" borderRadius={0} />
                <Skeleton height={16} className="mt-[12px]" borderRadius={0} />
              </li>
            ))}
          </ul>
          <div className="w-full flex mt-[102px] mb-[120px] justify-end gap-[132px]">
            <Skeleton
              height={40}
              width={280}
              borderRadius={0}
              className="justify-center"
            />
            <Skeleton
              height={21}
              width={205}
              className="mt-[16px] mr-[20px]"
              borderRadius={0}
            />
          </div>
        </>
      )}
    </Section>
  ) : (
    <>{children}</>
  )
}

export default ProductGridSkeleton
