import StarsReview from 'src/components/ui/StarsReview/StarsReview'

import type { FacetValue } from '../../types'

import './style.scss'

type Props = {
  facetValue: FacetValue
  onFacetChange(value: { key: string; value: string }): void
  showQuantity?: boolean
}

export function Stars({ facetValue, onFacetChange, showQuantity }: Props) {
  return (
    <label
      htmlFor={facetValue.value}
      className="checkbox_default font-roboto text-sm checkbox_default flex"
      data-store-label
      data-testid="store-label"
    >
      <input
        type="checkbox"
        name={facetValue.key}
        id={facetValue.value}
        checked={facetValue.selected}
        onChange={() =>
          onFacetChange({ key: facetValue?.key, value: facetValue?.value })
        }
        data-value={facetValue.value}
        data-quantity={facetValue.quantity}
        data-store-checkbox
      />

      <span className="text-darkGray inline-flex items-center">
        <StarsReview stars={Number(facetValue.label)} variant="black" />
        {showQuantity && (
          <span className="mobile-body-regular-text2 tablet-body-regular-text2 desktop-body-regular-text3 text-restructure-tertiary ml-1">
            ({facetValue.quantity})
          </span>
        )}
      </span>
    </label>
  )
}
